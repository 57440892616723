import axios from './http'

// 获取人脸设备列表
export const getFaceDevicePageList = (params) => axios.get('/SecurityApi/FaceDevice/GetPageList', { params: params })
// 删除人脸识别设备
export const deleteFaceDevices = (data) => axios.post('/SecurityApi/FaceDevice/Delete', data)
// 获取设备详情
export const getFaceDeviceDetail = (params) => axios.get('/SecurityApi/FaceDevice/GetDetail', { params: params })
// 新增人脸识别机设备
export const addFaceDevice = (data) => axios.post('/SecurityApi/FaceDevice/Add', data)
// 修改人脸识别机设备
export const editFaceDevice = (data) => axios.post('/SecurityApi/FaceDevice/Update', data)
// 刷新设备配置
export const refreshDeviceConfig = (params) => axios.get('/SecurityApi/FaceDevice/RefreshDeviceConfig', { params: params })
// 获取人脸设备开门条件
export const getFaceDeviceOpenTypeByModelNamel = (params) => axios.get('/ParkingLotApi/ParkingEnum/GetOpenTypeByModelName', { params: params })
// 上传图片
export const uploadImage = (data) => {
  return axios({
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    contentType: 'multipart/form-data',
    url: '/FileUploadServiceApi/picture/UploadPic',
    data
  })
}
// 上传文件
export const uploadFile = (data) => {
  return axios({
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    contentType: 'multipart/form-data',
    url: '/FileUploadServiceApi/file/UploadFile',
    data
  })
}
// 配置人脸识别设备
export const setDeviceConfig = (data) => axios.post('/SecurityApi/FaceDevice/SetDeviceConfig', data)
// 获取人脸设备实时视频
export const getFaceDeviceNVRMonitor = (data) => axios.post('/SecurityApi/FaceDevice/NVR_Monitor', data)
// 开门
export const openFaceDevice = (data) => axios.post('/SecurityApi/FaceDevice/Unlock', data)
// 重启设备
export const resetFaceDevice = (data) => axios.post('/SecurityApi/FaceDevice/RestartDevice', data)
// 恢复出厂设置
export const factoryResetFaceDevice = (data) => axios.post('/SecurityApi/FaceDevice/FactoryDefault', data)
// 获取权限组列表
export const getRoomPageList = (params) => axios.get('/SecurityApi/PermissionGroup/GetPageList', { params: params })
// 获取组织列表下拉列
export const getOrganizationSeleteList = (params) => axios.get('/SecurityApi/PermissionGroup/GetSeleteList', { params: params })
// 删除权限组
export const deleteRoom = (data) => axios.post('/SecurityApi/PermissionGroup/Delete', data)
// 获取权限组详情
export const getRoomDetail = (params) => axios.get('/SecurityApi/PermissionGroup/GetDetail', { params: params })
// 适用人员
export const getDetailByOrageni = (params) => axios.get('/SecurityApi/PermissionGroup/GetDetailByOrageni', { params: params })
// 新增权限组
export const addRoom = (data) => axios.post('/SecurityApi/PermissionGroup/Add', data)
// 修改权限组
export const updateRoom = (data) => axios.post('/SecurityApi/PermissionGroup/Update', data)
// 获取门禁设备列表（新增权限组页面，新增组织页面）
export const getFaceDeviceList = (params) => axios.get('/SecurityApi/FaceDevice/GetList', { params: params })
// 获取通行记录列表
export const getPersonRecordPageList = (params) => axios.get('/SecurityApi/PersonRecord/GetPageList', { params: params })
// 获取通行记录详情
export const getPersonRecordDetailt = (params) => axios.get('/SecurityApi/PersonRecord/GetDetail', { params: params })
// 以图搜图（人脸）
export const searchFaceByFace = (data) => axios.post('/SecurityApi/FaceFind/SearchFaceByFace', data)
// 获取设备日志列表
export const getDeviceLogList = (params) => axios.get('/SecurityApi/DeviceFaceCommandRecord/GetPageList', { params: params })
